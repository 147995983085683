<template>
    <!-- <v-container> -->
    <div>
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#1976d2"
        size="128"
        is-full-screen
      />
      <v-snackbar v-model="showsnackbar" color="black" right>
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showsnackbar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout pt-5 v-if="stats != ''" wrap justify-start>
        <v-flex xs12 sm6 lg3 text-left pa-2 v-if="type != 'SubAdmin'">
          <v-card rounded="lg" elevation="6" class="bg1" height="150px">
            <v-layout wrap justify-end pa-4>
              <v-flex xs9 lg8 xl7 text-left>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left>
                    <span class="analyticsValue"> {{ stats.activeUsers }} </span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <!-- <router-link to="/users/user" style="text-decoration: none"> -->
                      <span class="analyticsKey"> TOTAL USERS </span>
                      <span class="itemArrow"> >> </span>
                    <!-- </router-link> -->
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs3 xl4 pt-5 text-center align-self-center>
                <v-img
                  src="../../../assets/iconsets/users.png"
                  height="6vh"
                  contain
                ></v-img>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 lg3 text-left pa-2>
          <v-card rounded="lg" elevation="6" class="bg4" height="150px">
            <v-layout wrap justify-end pa-4>
              <v-flex xs9 lg8 xl7 text-left>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left>
                    <span class="analyticsValue"> {{ stats.totalSellers }}</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <!-- <router-link
                      to="/sellers/seller"
                      style="text-decoration: none"
                    > -->
                      <span class="analyticsKey"> SELLERS </span>
                      <span class="itemArrow"> >> </span>
                    <!-- </router-link> -->
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs3 xl4 pt-5 text-center align-self-center>
                <v-img
                  src="../../../assets/iconsets/sellers.png"
                  height="6vh"
                  contain
                ></v-img>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 lg3 text-left pa-2>
          <v-card rounded="lg" elevation="6" class="bg3" height="150px">
            <v-layout wrap justify-end pa-4>
              <v-flex xs9 lg8 xl7 text-left>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left>
                    <span class="analyticsValue"> {{ stats.totalProducts }}</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <!-- <router-link
                      to="/sellers/seller"
                      style="text-decoration: none"
                    > -->
                      <span class="analyticsKey"> TOTAL PRODUCTS </span>
                      <span class="itemArrow"> >> </span>
                    <!-- </router-link> -->
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs3 xl4 pt-5 text-center align-self-center>
                <v-img
                  src="../../../assets/iconsets/program.png"
                  height="6vh"
                  contain
                ></v-img>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 lg3 text-left pa-2>
          <v-card rounded="lg" elevation="6" class="bg2" height="150px">
            <v-layout wrap justify-end pa-4>
              <v-flex xs9 lg8 xl7 text-left>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left>
                    <span class="analyticsValue"> {{ stats.totalOrders }}</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <!-- <router-link
                      to="/purchases/purchaseList"
                      style="text-decoration: none"
                    > -->
                      <span class="analyticsKey"> TOTAL ORDERS </span>
                      <span class="itemArrow"> >> </span>
                    <!-- </router-link> -->
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs3 xl4 pt-5 text-center align-self-center>
                <v-img
                  src="../../../assets/iconsets/purchase.png"
                  height="6vh"
                  contain
                ></v-img>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
        <v-layout wrap justify-end pt-5>
          <v-flex xs4 sm3 md3 lg3 xl2 pa-2 text-right>
        <v-select
          outlined
          dense
          color="#f5f5f529"
          placeholder="Seller"
          v-model="seller"
          :items="shop"
          item-text="shopName"
          item-value="_id"
          item-color="#70707029"
          hide-details
          class="analyticsSelector rounded-lg"
          v-on:input="getGraph"
        >
          <template v-slot:item="{ item }">
            <span
              class="text-left"
              style="font-family: opensansbold; font-size: 18px; color: #041c4b"
            >
              {{ item.shopName }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span
              style="font-family: opensansbold; font-size: 18px; color: #041c4b"
            >
              {{ item.shopName }}
            </span>
          </template>
        </v-select>
      </v-flex>
          <v-flex xs4 sm3 md3 lg3 xl2 pa-2 text-right>
        <v-select
          outlined
          dense
          color="#f5f5f529"
          placeholder="Sort"
          v-model="sortItem"
          :items="sortItems"
          item-text="name"
          item-value="value"
          item-color="#70707029"
          hide-details
          class="analyticsSelector rounded-lg"
          v-on:input="getGraph"
        >
          <template v-slot:item="{ item }">
            <span
              class="text-left"
              style="font-family: opensansbold; font-size: 18px; color: #041c4b"
            >
              {{ item.name }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span
              style="font-family: opensansbold; font-size: 18px; color: #041c4b"
            >
              {{ item.name }}
            </span>
          </template>
        </v-select>
      </v-flex>
     <v-flex xs12>
        <div id="chart" v-if="flag">
          <apexchart
          width="100%"
          height="300px"
          type="line"
          :options="chartOptions"
          :series="series"
        ></apexchart>
        </div>
      </v-flex>
    </v-layout>
      <!-- <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text class="pa-5">
              <h3
                class="
                  title
                  blue-grey--text
                  text--darken-2
                  font-weight-regular
                  mb-4
                "
              >
                Last 10 Purchases
                <span
                  ><v-btn
                    @click="$router.push('/purchases/purchaseList')"
                    text
                    color="blue"
                    >View All</v-btn
                  ></span
                >
              </h3>
              <v-flex xs12 py-2 v-for="(item, i) in user" :key="i">
                <v-card flat rounded="xl" color="#F5F7FC" outlined>
                  <v-layout px-4 wrap justify-center>
                    <v-flex xs12>
                      <v-layout wrap justify-center>
                        <v-flex xs3 sm2 pa-2 align-self-center>
                          <v-img
                            :src="baseURL + item.buyer.image"
                            height="80px"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#FF4343"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-flex>
                        <v-flex pt-4 xs9 sm10 text-left>
                          <v-layout wrap justify-center>
                            <v-flex xs12 sm6>
                              <v-layout wrap justify-center>
                                <v-flex xs12 text-left>
                                  <span style="font-size: 16px; color: #808080">
                                    {{ item.buyer.firstname }}
                                    {{ item.buyer.lastname }}
                                  </span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span style="font-size: 14px; color: #000000">
                                    Email: {{ item.email }}
                                  </span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span style="font-size: 14px; color: #000000">
                                    Phone: {{ item.mobile }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs4 sm2>
                              <v-layout wrap justify-center>
                                <v-flex xs12 text-left>
                                  <span style="font-size: 16px; color: #808080">
                                    Productname
                                  </span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span style="font-size: 14px; color: #000000">
                                    {{ item.productname }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs4 sm2>
                              <v-layout wrap justify-center>
                                <v-flex xs12 text-left>
                                  <span style="font-size: 16px; color: #808080">
                                    Method
                                  </span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span style="font-size: 14px; color: #000000">
                                    {{ item.method }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs4 sm2>
                              <v-layout wrap justify-center>
                                <v-flex xs12 text-left>
                                  <span style="font-size: 16px; color: #808080">
                                    Amount
                                  </span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span style="font-size: 14px; color: #000000">
                                    {{ item.amount }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
    </div>
    <!-- </v-container> -->
  </template>
  
  <script>
  const gradients = [
    ["#6c757d"],
    ["#6c757d"],
    ["#6c757d", "#6c757d", "#6c757d"],
    ["#21c1d6", "21c1d6"],
    ["#21c1d6", "#21c1d6", "#21c1d6"],
    ["#21c1d6", "#21c1d6", "#21c1d6"],
  ];
  
  const gradients2 = [
    ["#1e88e5"],
    ["#1e88e5"],
    ["#1e88e5", "#1e88e5", "#1e88e5"],
    ["#1e88e5", "#1e88e5"],
    ["#1e88e5", "#1e88e5", "#1e88e5"],
    ["#1e88e5", "#1e88e5", "#1e88e5"],
  ];
  import axios from "axios";
  export default {
    name: "BasicDashboard",
    data: () => ({
      fill: true,
      stats: "",
      user: [],
      type: "",
      flag: false,
      dialog: false,
      sortItems: [
        { name: "2023", value: "2023" },
        { name: "2024", value: "2024" },
        { name: "2025", value: "2025" },
      ],
      sortItem: "2024",
      shop:[],
      seller:"",
      graph: [],
      gradient: gradients[4],
      gradients,
      padding: 8,
      radius: 50,
      value: [0, 2, 5, 9, 5, 10, 3, 5, 1, 8, 2, 9, 0],
      width: 1,
  
      fill2: true,
      appLoading: false,
      gradient2: gradients2[4],
      gradients2,
      radius2: 0,
      padding2: 0,
      value2: [1, 2, 5, 9, 5, 10, 3, 5, 1, 8, 2, 9, 4],
      width2: 4,
      showsnackbar: false,
      msg: null,
      task: null,
      selected: [2],
      rules: {
        required: (value) => !!value || "Required.",
      },
      xaxis: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      series: [
        // {
        //   data:[]
        // },
        // {
        //   data:[]
        // }
      ],
      graphData: {
        graph: [],
      },
      title: null,
      message: "",
    }),
    computed: {
    chartOptions: function () {
      var that = this;
      return {
        chart: {
          id: "SellerAnalytics",
          yaxis: {
            labels: {
              formatter: function (value) {
                return value.toFixed(2);
              },
            },
          },
          toolbar: {
            show: false,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          sparkline: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },

        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          tooltip: {
            enabled: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
            colorStops: [
              {
                offset: 0,
                color: "#069EFC",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#14F4C9",
                opacity: 1,
              },
            ],
          },
        },
        dataLabels: {
          enabled: false, // Hide data labels
        },
        tooltip: {
          custom: function ({ series, dataPointIndex }) {
            var content =
              `<div data-v-5adcc081="" class="flex xs12" style="background-color:transparent">
                <div data-v-5adcc081="" class="rounded-t-lg v-card v-card--flat v-sheet  rounded-0" style="background-color: rgb(17, 39, 84);">
                  <div data-v-5adcc081="" class="layout wrap justify-start py-1 px-2">
                    <div data-v-5adcc081="" class="flex xs8 xl9 text-left">
                      <span data-v-5adcc081="" style="font-family: opensanslight; font-size: 14px; color: rgb(201, 201, 201);"> Total Sales </span>
                    </div>
                    <div data-v-5adcc081="" class="flex xs4 xl3 text-left">
                      <span data-v-5adcc081="" style="font-family: opensanslight; font-size: 14px; color: rgb(201, 201, 201);">` +
              that.xaxis[dataPointIndex] +
              `</span>
                    </div>
                  </div>
                </div>
                <div data-v-5adcc081="" class="rounded-b-lg v-card v-card--flat v-sheet theme--light rounded-0" style="background-color: rgb(4, 28, 75); ">
                  <div data-v-5adcc081="" class="layout wrap justify-center px-2">
                    <div data-v-5adcc081="" class="flex xs12 text-left">
                      <span data-v-5adcc081="" style="font-family: opensansbold; font-size: 19px; color: rgb(255, 255, 255);">₹` +
              series[0][dataPointIndex].toFixed(2) +
              `</span>
                    </div>
                    <div data-v-5adcc081="" class="flex xs12 text-left">
                      <span data-v-5adcc081="" style="font-family: opensansbold; font-size: 12px; color: rgb(61, 193, 49);">Transactions: ` +
              that.graphData.graph[1].data[dataPointIndex] +
              `</span>
                    </div>
                  </div>
                </div>
              </div>`;
            return content;
          },
        },
      };
    },
  },
    mounted() {
      this.loadStats();
      this.getGraph()
      this.getSeller()
    },
    methods: {
      create() {
        this.tasks.push({
          done: false,
          text: this.task,
        });
        this.task = null;
      },
      loadStats() {
        this.appLoading = true;
        axios({
          url: "/adminDashBoard",
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.stats = response.data;
            this.type = response.data.type;
            this.user = response.data.data.lasttenpurchases;
          })
          .catch((e) => {
            this.appLoading = false;
            this.errors.push(e);
          });
      },
      getGraph() {
      this.appLoading = true;
      axios({
        url: "/order/admin/graph",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          year: this.sortItem,
          sellerId:this.seller
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.graphData = response.data.data;
            // if (this.graphData) {
            //   this.series.push(this.graphData.graph[0]);
            // }
            for (var i = 0; i < this.graphData.graph.length; i++) {
            this.series=[{data:this.graphData.graph[0].data}]
          }
            this.flag = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((e) => {
          this.appLoading = false;
          this.errors.push(e);
        });
    },
    getSeller() {
      this.appLoading = true;
      axios({
        url: "/seller/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.shop = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
      addNotification() {
        var data = {};
        data["title"] = this.title;
        data["message"] = this.message;
        axios({
          url: "/send/notification/all/customers",
          method: "POST",
          data: data,
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.title = null;
              (this.message = ""), (this.dialog = false);
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  <style scoped>
  .analyticsSelector {
      background-color: #f5f7fc;
  }
  
  .analyticsKey {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 1px;
  }
  .analyticsValue {
    font-size: 22px;
    color: #ffffff;
  }
  .itemArrow {
    font-size: 12px;
    color: #ffffff;
  }
  .bg1 {
    background-image: linear-gradient(to right, #00f2fe 0%, #4facfe 100%);
  }
  .bg2 {
    background-image: linear-gradient(to right, #f093fb 0%, #f5576c 100%);
  }
  .bg3 {
    background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
  }
  .bg4 {
    background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
  }
  .bg5 {
    background-image: linear-gradient(to right, #cae230 0%, #0bc60b 100%);
  }
  </style>
  